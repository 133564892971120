import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "changelog"
    }}>{`Changelog`}</h1>
    <h3 {...{
      "id": "v-440"
    }}>{`[v 4.4.0]`}</h3>
    <ul>
      <li parentName="ul">{`connecting control buttons to shift carousel when using disableAutoShift prop`}</li>
      <li parentName="ul">{`this prop does not support boundary control so needs to be used with infinite`}</li>
      <li parentName="ul">{`specifically tested this fix with same props used in D365 mobile carousel`}</li>
    </ul>
    <p><em parentName="p">{`Kathryn Costantin`}</em></p>
    <h3 {...{
      "id": "v-430"
    }}>{`[v. 4.3.0]`}</h3>
    <ul>
      <li parentName="ul">{`Adding disableAutoShiftProp to carousel component`}</li>
      <li parentName="ul">{`this prop is not yet perfect, it is just to test`}</li>
    </ul>
    <p><em parentName="p">{`Kathryn Costantin`}</em></p>
    <h3 {...{
      "id": "v-424"
    }}>{`[v. 4.2.4]`}</h3>
    <ul>
      <li parentName="ul">{`Adding onCloseAction() on the cleanup function for the dialog hook.`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h3 {...{
      "id": "v-423"
    }}>{`[v. 4.2.3]`}</h3>
    <ul>
      <li parentName="ul">{`Removed dialog-marker data attribute for controlling dialog positioning`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h3 {...{
      "id": "v-422"
    }}>{`[v. 4.2.2]`}</h3>
    <ul>
      <li parentName="ul">{`Fix dialog positioning for ios/safari`}</li>
      <li parentName="ul">{`Fix nested modal overflow issue`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h3 {...{
      "id": "v-421"
    }}>{`[v. 4.2.1]`}</h3>
    <ul>
      <li parentName="ul">{`Pass background opacity by ref to modal/drawer components.`}</li>
    </ul>
    <p><em parentName="p">{`Franklyn`}</em></p>
    <h3 {...{
      "id": "v-420"
    }}>{`[v. 4.2.0]`}</h3>
    <ul>
      <li parentName="ul">{`Adding new icons to Mattress Features`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h3 {...{
      "id": "v-411"
    }}>{`[v. 4.1.1]`}</h3>
    <ul>
      <li parentName="ul">{`Added whitespace detection and adjustment for non-infinite carousel`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel / Sergio Herrera`}</em></p>
    <h3 {...{
      "id": "v-404"
    }}>{`[v. 4.0.4]`}</h3>
    <ul>
      <li parentName="ul">{`Added aria label override in Step.`}</li>
    </ul>
    <p><em parentName="p">{`Andrew Hahn`}</em></p>
    <h3 {...{
      "id": "v-403"
    }}>{`[v. 4.0.3]`}</h3>
    <ul>
      <li parentName="ul">{`Added itemID to Step to make it queryable.`}</li>
    </ul>
    <h3 {...{
      "id": "v-402"
    }}>{`[v. 4.0.2]`}</h3>
    <ul>
      <li parentName="ul">{`Added toggle for focus() on AccordionItem.`}</li>
    </ul>
    <h3 {...{
      "id": "v-401"
    }}>{`[v. 4.0.1]`}</h3>
    <ul>
      <li parentName="ul">{`Added null check for Carousel component.`}</li>
    </ul>
    <p><em parentName="p">{`Franklyn`}</em></p>
    <h2 {...{
      "id": "v-400"
    }}>{`[v. 4.0.0]`}</h2>
    <ul>
      <li parentName="ul">{`Revamped Price component`}</li>
      <li parentName="ul">{`updated existing props. added new props.`}</li>
      <li parentName="ul">{`updated styling`}</li>
      <li parentName="ul">{`added helper functions`}</li>
      <li parentName="ul">{`updated documentation`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h3 {...{
      "id": "v-393"
    }}>{`[v. 3.9.3]`}</h3>
    <ul>
      <li parentName="ul">{`added dark variation to Info Icon`}</li>
    </ul>
    <p><em parentName="p">{`Jason Campbell`}</em></p>
    <h3 {...{
      "id": "v-392"
    }}>{`[v. 3.9.2]`}</h3>
    <ul>
      <li parentName="ul">{`updated Chat Bubble Icon`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-391"
    }}>{`[v. 3.9.1]`}</h4>
    <ul>
      <li parentName="ul">{`fixed backdrop prop type`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h3 {...{
      "id": "v-390"
    }}>{`[v. 3.9.0]`}</h3>
    <ul>
      <li parentName="ul">{`Added option to add a backdrop to tooltip`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-382"
    }}>{`[v. 3.8.2]`}</h4>
    <ul>
      <li parentName="ul">{`Typescript Select Component`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-381"
    }}>{`[v. 3.8.1]`}</h4>
    <ul>
      <li parentName="ul">{`Fixed the following issues when using the textarea character limit prop:`}
        <ul parentName="li">
          <li parentName="ul">{`Border radius styling`}</li>
          <li parentName="ul">{`When used in conjunction w/inputMessage`}</li>
          <li parentName="ul">{`Updating character count when valueDerived is cleared`}</li>
        </ul>
      </li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h3 {...{
      "id": "v-380"
    }}>{`[v. 3.8.0]`}</h3>
    <ul>
      <li parentName="ul">{`Added character count/limit to Textarea`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h3 {...{
      "id": "v-376"
    }}>{`[v. 3.7.6]`}</h3>
    <ul>
      <li parentName="ul">{`Bugfix for initial focus on Accordion Item`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h3 {...{
      "id": "v-375"
    }}>{`[v. 3.7.5]`}</h3>
    <ul>
      <li parentName="ul">{`Fixed Input label animation lag for iOS devices`}</li>
    </ul>
    <p><em parentName="p">{`Lee Collins`}</em></p>
    <h3 {...{
      "id": "v-374"
    }}>{`[v. 3.7.4]`}</h3>
    <ul>
      <li parentName="ul">{`Carousel swiping on mobile more flick like than drag.`}</li>
    </ul>
    <p><em parentName="p">{`Zoe Jablow`}</em></p>
    <h3 {...{
      "id": "v-373"
    }}>{`[v. 3.7.3]`}</h3>
    <ul>
      <li parentName="ul">{`Fixed the label on Input component to reset with form clear`}</li>
    </ul>
    <p><em parentName="p">{`Lee Collins`}</em></p>
    <h3 {...{
      "id": "v-372"
    }}>{`[v. 3.7.2]`}</h3>
    <ul>
      <li parentName="ul">{`Preventing scroll when modals and drawers are open`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h3 {...{
      "id": "v-371"
    }}>{`[v. 3.7.1]`}</h3>
    <ul>
      <li parentName="ul">{`Added fix to remove shadows inside inputs on iOS devices`}</li>
    </ul>
    <p><em parentName="p">{`Lee Collins`}</em></p>
    <h3 {...{
      "id": "v-370"
    }}>{`[v. 3.7.0]`}</h3>
    <ul>
      <li parentName="ul">{`Updated Portal to be able to create nested portals`}</li>
      <li parentName="ul">{`Updated Modal, Drawer and Tooltip logic to allow for nested components`}</li>
      <li parentName="ul">{`Updated Documentation for Modal and Drawer to showcase nested capabilities`}</li>
      <li parentName="ul">{`Added props to Modal, Drawer and Tooltip to allow for granular control over backdrop and z-index`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-361"
    }}>{`[v. 3.6.1]`}</h4>
    <ul>
      <li parentName="ul">{`Update Tabs initial focus state`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h4 {...{
      "id": "v-3518"
    }}>{`[v. 3.5.18]`}</h4>
    <ul>
      <li parentName="ul">{`Update carousel disabled arrows`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h4 {...{
      "id": "v-3517"
    }}>{`[v. 3.5.17]`}</h4>
    <ul>
      <li parentName="ul">{`Bugfixes and additional options for carousel`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h4 {...{
      "id": "v-3516"
    }}>{`[v. 3.5.16]`}</h4>
    <ul>
      <li parentName="ul">{`Update checkbox label to allow ReactNode`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-3515"
    }}>{`[v. 3.5.15]`}</h4>
    <ul>
      <li parentName="ul">{`Update tooltip on hover`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h4 {...{
      "id": "v-3514"
    }}>{`[v. 3.5.14]`}</h4>
    <ul>
      <li parentName="ul">{`Added aria label to Drawer wrapper div`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h4 {...{
      "id": "v-3513"
    }}>{`[v. 3.5.13]`}</h4>
    <ul>
      <li parentName="ul">{`Added aria label prop to Portal`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h4 {...{
      "id": "v-3511-12"
    }}>{`[v. 3.5.11-12]`}</h4>
    <ul>
      <li parentName="ul">{`Added modalRef prop to Modal`}</li>
    </ul>
    <p><em parentName="p">{`Chris Carrillo`}</em></p>
    <h4 {...{
      "id": "v-3510"
    }}>{`[v. 3.5.10]`}</h4>
    <ul>
      <li parentName="ul">{`Added tooltip aria role`}</li>
      <li parentName="ul">{`Added aria role prop to Portal`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-359"
    }}>{`[v. 3.5.9]`}</h4>
    <ul>
      <li parentName="ul">{`Added children prop to Popper`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-358"
    }}>{`[v. 3.5.8]`}</h4>
    <ul>
      <li parentName="ul">{`Updated modal scroll bar styling`}</li>
      <li parentName="ul">{`Added back borderStyle prop`}</li>
      <li parentName="ul">{`Updated header/subheader props to accept React Elements`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h4 {...{
      "id": "v-357"
    }}>{`[v. 3.5.7]`}</h4>
    <ul>
      <li parentName="ul">{`Added ToddlerBedding SVG`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h4 {...{
      "id": "v-356"
    }}>{`[v. 3.5.6]`}</h4>
    <ul>
      <li parentName="ul">{`Added rightText prop on Price component`}</li>
    </ul>
    <p><em parentName="p">{`David Bradshaw`}</em></p>
    <h4 {...{
      "id": "v-355"
    }}>{`[v. 3.5.5]`}</h4>
    <ul>
      <li parentName="ul">{`ProgressBar styles`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-354"
    }}>{`[v. 3.5.4]`}</h4>
    <ul>
      <li parentName="ul">{`Updated Accordion Title to allow ReactNode types`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-353"
    }}>{`[v. 3.5.3]`}</h4>
    <ul>
      <li parentName="ul">{`Added capture propagation prop to Carousel`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h4 {...{
      "id": "v-352"
    }}>{`[v. 3.5.2]`}</h4>
    <ul>
      <li parentName="ul">{`Added null check in carousel utils to account for null ref`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h4 {...{
      "id": "v-351"
    }}>{`[v. 3.5.1]`}</h4>
    <ul>
      <li parentName="ul">{`Updated Nighttime Icon with stroke prop`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h3 {...{
      "id": "v-350"
    }}>{`[v. 3.5.0]`}</h3>
    <ul>
      <li parentName="ul">{`Updated Carousel with the following:`}
        <ul parentName="li">
          <li parentName="ul">{`Added variable width capability`}</li>
          <li parentName="ul">{`Added built-in carousel for chips`}</li>
          <li parentName="ul">{`Added disable control prop`}</li>
        </ul>
      </li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h4 {...{
      "id": "v-347"
    }}>{`[v. 3.4.7]`}</h4>
    <ul>
      <li parentName="ul">{`Updated PanelItem for ADA compliance`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-346"
    }}>{`[v. 3.4.6]`}</h4>
    <ul>
      <li parentName="ul">{`Add checkmark outline icon.`}</li>
    </ul>
    <p><em parentName="p">{`Zoe Jablow`}</em></p>
    <h4 {...{
      "id": "v-345"
    }}>{`[v. 3.4.5]`}</h4>
    <ul>
      <li parentName="ul">{`Add border options to popper`}</li>
    </ul>
    <p><em parentName="p">{`Zoe Jablow`}</em></p>
    <h4 {...{
      "id": "v-344"
    }}>{`[v. 3.4.4]`}</h4>
    <ul>
      <li parentName="ul">{`Clean up Modal on unmount`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-343"
    }}>{`[v. 3.4.3]`}</h4>
    <ul>
      <li parentName="ul">{`Added a discount percentage in the Price component`}</li>
    </ul>
    <p><em parentName="p">{`Jason Campbell`}</em></p>
    <h4 {...{
      "id": "v-342"
    }}>{`[v. 3.4.2]`}</h4>
    <ul>
      <li parentName="ul">{`Tooltip controlled from inside the container`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-341"
    }}>{`[v. 3.4.1]`}</h4>
    <ul>
      <li parentName="ul">{`A bothersome bug fixed with the Tooltip and React reconciliation`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h3 {...{
      "id": "v-340"
    }}>{`[v. 3.4.0]`}</h3>
    <ul>
      <li parentName="ul">{`Revamped tooltip by adding: borderColor, closeBtn, maxWidth, dynamic height`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-332"
    }}>{`[v. 3.3.2]`}</h4>
    <ul>
      <li parentName="ul">{`Fixed hyphen case in icon SVGs`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-331"
    }}>{`[v. 3.3.1]`}</h4>
    <ul>
      <li parentName="ul">{`Added Social Icons' red variants`}</li>
      <li parentName="ul">{`Added Info, Nighttime and Cart Icons`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-330"
    }}>{`[v. 3.3.0]`}</h4>
    <ul>
      <li parentName="ul">{`Updated Tabs component to represent styles`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-322"
    }}>{`[v. 3.2.2]`}</h4>
    <ul>
      <li parentName="ul">{`Reverted pagination logic when total items changes.`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h4 {...{
      "id": "v-321"
    }}>{`[v. 3.2.1]`}</h4>
    <ul>
      <li parentName="ul">{`Updated pagination total and current items to be stateful.`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h3 {...{
      "id": "v-320"
    }}>{`[v. 3.2.0]`}</h3>
    <ul>
      <li parentName="ul">{`Skeleton Component added.`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h3 {...{
      "id": "v-310"
    }}>{`[v. 3.1.0]`}</h3>
    <ul>
      <li parentName="ul">{`Popper Component added.`}</li>
    </ul>
    <p><em parentName="p">{`Zoe Jablow`}</em></p>
    <h4 {...{
      "id": "v-301"
    }}>{`[v. 3.0.1]`}</h4>
    <ul>
      <li parentName="ul">{`Adjusted pagination element structure for better screen reader compliance`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h2 {...{
      "id": "v-300"
    }}>{`[v. 3.0.0]`}</h2>
    <ul>
      <li parentName="ul">{`Deleted ProductCard:`}
        <ul parentName="li">
          <li parentName="ul">{`Not used`}</li>
          <li parentName="ul">{`Every app has different styling`}</li>
          <li parentName="ul">{`We already have the basic components to build one (Rating, Pricing) the rest is just CSS.`}</li>
          <li parentName="ul">{`You would have to override it constantly which defeats the purpose of the component.`}</li>
        </ul>
      </li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-298"
    }}>{`[v. 2.9.8]`}</h4>
    <ul>
      <li parentName="ul">{`Updated ProductCard reivew and price props to be optional`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-297"
    }}>{`[v. 2.9.7]`}</h4>
    <ul>
      <li parentName="ul">{`Updated ProductCard with:`}
        <ul parentName="li">
          <li parentName="ul">{`Alternate layout orientation`}</li>
          <li parentName="ul">{`Lazy loaded brand and comfort logos`}</li>
          <li parentName="ul">{`Various class props for granular style overriding`}</li>
        </ul>
      </li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-296"
    }}>{`[v. 2.9.6]`}</h4>
    <ul>
      <li parentName="ul">{`updated pagination item count to be stateful`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-295"
    }}>{`[v. 2.9.5]`}</h4>
    <ul>
      <li parentName="ul">{`pagination hotfix - removed console logs`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-294"
    }}>{`[v. 2.9.4]`}</h4>
    <ul>
      <li parentName="ul">{`Updated the pagination item count verbiage logic`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-293"
    }}>{`[v. 2.9.3]`}</h4>
    <ul>
      <li parentName="ul">{`Added ellipses prop to Pagination`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-292"
    }}>{`[v. 2.9.2]`}</h4>
    <ul>
      <li parentName="ul">{`Add id prop to modal and drawer`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h4 {...{
      "id": "v-291"
    }}>{`[v. 2.9.1]`}</h4>
    <ul>
      <li parentName="ul">{`Fix Carousel loading new slides`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h3 {...{
      "id": "v-290"
    }}>{`[v. 2.9.0]`}</h3>
    <ul>
      <li parentName="ul">{`Init Chip Component`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-284"
    }}>{`[v. 2.8.4]`}</h4>
    <ul>
      <li parentName="ul">{`Added title className props to tabs and accordion to update title styles`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h4 {...{
      "id": "v-283"
    }}>{`[v. 2.8.3]`}</h4>
    <ul>
      <li parentName="ul">{`Remove "enter" keypress to close things (modals, drawer, tooltip)
as sometimes we need "enter" for form inputs. It's also not part of accessibility.`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-282"
    }}>{`[v. 2.8.2]`}</h4>
    <ul>
      <li parentName="ul">{`Fixed Modal controlled closing bug`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-281"
    }}>{`[v. 2.8.1]`}</h4>
    <ul>
      <li parentName="ul">{`Fixed Modal controlled closing bug`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h3 {...{
      "id": "v-280"
    }}>{`[v. 2.8.0]`}</h3>
    <p>{`Modal:`}</p>
    <ul>
      <li parentName="ul">{`Fixed default width and css bugs, esp with mobile`}</li>
      <li parentName="ul">{`Removed borderStyle and just made round as default`}</li>
      <li parentName="ul">{`Fixed Safari scroll bug`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-275"
    }}>{`[v. 2.7.5]`}</h4>
    <ul>
      <li parentName="ul">{`Added More icons and made all icons' width/height customizable`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-274"
    }}>{`[v. 2.7.4]`}</h4>
    <ul>
      <li parentName="ul">{`Removed !important from disabled Button styling`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-273"
    }}>{`[v. 2.7.3]`}</h4>
    <ul>
      <li parentName="ul">{`Updated Carousel to handle capture click event while dragging`}</li>
      <li parentName="ul">{`Added legacy control button option`}</li>
      <li parentName="ul">{`Added control button class prop`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h4 {...{
      "id": "v-272"
    }}>{`[v. 2.7.2]`}</h4>
    <ul>
      <li parentName="ul">{`Updated Modal and Drawer class props`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h4 {...{
      "id": "v-271"
    }}>{`[v. 2.7.1]`}</h4>
    <ul>
      <li parentName="ul">{`Added horizontal style accordion.`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h3 {...{
      "id": "v-270"
    }}>{`[v. 2.7.0]`}</h3>
    <ul>
      <li parentName="ul">{`Added brands (like Serta, Purple, etc) to icons`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-268"
    }}>{`[v. 2.6.8]`}</h4>
    <ul>
      <li parentName="ul">{`Updated accordion item id. Added aria labels for stepper steps`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-267"
    }}>{`[v. 2.6.7]`}</h4>
    <ul>
      <li parentName="ul">{`Added single item carousel property/styling. Adjusted button link styling.`}</li>
    </ul>
    <p><em parentName="p">{`Zoe Jablow`}</em></p>
    <h4 {...{
      "id": "v-266"
    }}>{`[v. 2.6.6]`}</h4>
    <ul>
      <li parentName="ul">{`added forwardRef to Button`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-265"
    }}>{`[v. 2.6.5]`}</h4>
    <ul>
      <li parentName="ul">{`Updated props on accordionItem to have className & alignment properties on checkbox.`}</li>
    </ul>
    <p><em parentName="p">{`Zoe Jablow`}</em></p>
    <h4 {...{
      "id": "v-265-1"
    }}>{`[v. 2.6.5]`}</h4>
    <ul>
      <li parentName="ul">{`Updated props on accordionItem to have className & alignment properties on checkbox.`}</li>
    </ul>
    <p><em parentName="p">{`Zoe Jablow`}</em></p>
    <h4 {...{
      "id": "v-264"
    }}>{`[v. 2.6.4]`}</h4>
    <ul>
      <li parentName="ul">{`Updated styles of buttons (type links) to not have importants.`}</li>
    </ul>
    <p><em parentName="p">{`Zoe Jablow`}</em></p>
    <h4 {...{
      "id": "v-263"
    }}>{`[v. 2.6.3]`}</h4>
    <ul>
      <li parentName="ul">{`Updated styles to Product Card and added children prop to it`}</li>
    </ul>
    <p><em parentName="p">{`Lee Collins`}</em></p>
    <h4 {...{
      "id": "v-262"
    }}>{`[v. 2.6.2]`}</h4>
    <ul>
      <li parentName="ul">{`Add disabled prop to Stepper`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-261"
    }}>{`[v. 2.6.1]`}</h4>
    <ul>
      <li parentName="ul">{`Add size prop to Checkbox`}</li>
      <li parentName="ul">{`useForwardRefHasValue hook`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h3 {...{
      "id": "v-260"
    }}>{`[v. 2.6.0]`}</h3>
    <ul>
      <li parentName="ul">{`Rewrote the Checkbox component:`}
        <ul parentName="li">
          <li parentName="ul">{`Click on label for onChange`}</li>
          <li parentName="ul">{`Add controlled and uncontrolled capability`}</li>
          <li parentName="ul">{`Added backgroundColor, labelPlacement, wrapperClass, labelClass props`}</li>
          <li parentName="ul">{`Forward Ref`}</li>
        </ul>
      </li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h3 {...{
      "id": "v-251"
    }}>{`[v. 2.5.1]`}</h3>
    <ul>
      <li parentName="ul">{`Updated Styling and Functionality of Select Component`}</li>
    </ul>
    <p><em parentName="p">{`Lee Collins`}</em></p>
    <h3 {...{
      "id": "v-250"
    }}>{`[v. 2.5.0]`}</h3>
    <ul>
      <li parentName="ul">{`Init Slider Component`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-241"
    }}>{`[v. 2.4.1]`}</h4>
    <ul>
      <li parentName="ul">{`Fix Stepper border`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h3 {...{
      "id": "v-240"
    }}>{`[v. 2.4.0]`}</h3>
    <ul>
      <li parentName="ul">{`Init Breadcrumbs Component`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-239"
    }}>{`[v. 2.3.9]`}</h4>
    <ul>
      <li parentName="ul">{`Fixed icon svg sizes`}</li>
      <li parentName="ul">{`Removed masks on svgs`}</li>
    </ul>
    <p><em parentName="p">{`Zoe Jablow`}</em></p>
    <h4 {...{
      "id": "v-238"
    }}>{`[v. 2.3.8]`}</h4>
    <ul>
      <li parentName="ul">{`Made sure all props have a description`}</li>
      <li parentName="ul">{`Badge: removed height/width prop and added them as styles`}</li>
      <li parentName="ul">{`Checkbox: renamed wrapperClass to className`}</li>
      <li parentName="ul">{`NumberIncrementer: removed showLabel (just use label)`}</li>
      <li parentName="ul">{`Select: Added forwardRef`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-237"
    }}>{`[v. 2.3.7]`}</h4>
    <ul>
      <li parentName="ul">{`Added modal props for subheader, border style, and content class names`}</li>
      <li parentName="ul">{`Updated modal styling`}</li>
      <li parentName="ul">{`Updated close icon SVG`}</li>
      <li parentName="ul">{`Updated modal close button with close icon`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h4 {...{
      "id": "v-236"
    }}>{`[v. 2.3.6]`}</h4>
    <ul>
      <li parentName="ul">{`Add sleepy's brand SVG`}</li>
      <li parentName="ul">{`Added white as a color to Typography`}</li>
    </ul>
    <p><em parentName="p">{`Lee Collins`}</em></p>
    <h4 {...{
      "id": "v-235"
    }}>{`[v. 2.3.5]`}</h4>
    <ul>
      <li parentName="ul">{`Tooltip styles and ADA compliance`}</li>
    </ul>
    <p><em parentName="p">{`Zoe Jablow`}</em></p>
    <h4 {...{
      "id": "v-234"
    }}>{`[v. 2.3.4]`}</h4>
    <ul>
      <li parentName="ul">{`Updated Accordion styles and added props`}</li>
      <li parentName="ul">{`Updated prop nomenclature for Accordion and Accordion Item`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h4 {...{
      "id": "v-233"
    }}>{`[v. 2.3.3]`}</h4>
    <ul>
      <li parentName="ul">{`Added Carrier Logos to Icons`}</li>
      <li parentName="ul">{`Added Shipping Status Icons to General Icons`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-232"
    }}>{`[v. 2.3.2]`}</h4>
    <ul>
      <li parentName="ul">{`Updated Stepper styles and added props`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-231"
    }}>{`[v. 2.3.1]`}</h4>
    <ul>
      <li parentName="ul">{`Fix container css`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h4 {...{
      "id": "v-230"
    }}>{`[v. 2.3.0]`}</h4>
    <ul>
      <li parentName="ul">{`Added Icons to the component library`}</li>
    </ul>
    <p><em parentName="p">{`Lee Collins`}</em></p>
    <h4 {...{
      "id": "v-227"
    }}>{`[v. 2.2.7]`}</h4>
    <ul>
      <li parentName="ul">{`H1 css update`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h4 {...{
      "id": "v-226"
    }}>{`[v. 2.2.6]`}</h4>
    <ul>
      <li parentName="ul">{`Fix Input Component to have correct style with default values`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h4 {...{
      "id": "v-225"
    }}>{`[v. 2.2.5]`}</h4>
    <ul>
      <li parentName="ul">{`Updated Carousel component with:`}
        <ul parentName="li">
          <li parentName="ul">{`Updated styling for control buttons focus state`}</li>
        </ul>
      </li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel`}</em></p>
    <h4 {...{
      "id": "v-224"
    }}>{`[v. 2.2.4]`}</h4>
    <ul>
      <li parentName="ul">{`Updated Tab Component with:`}
        <ul parentName="li">
          <li parentName="ul">{`Animated styles, value and defaultValue prop`}</li>
          <li parentName="ul">{`Added controlled and uncontrolled behavior`}</li>
          <li parentName="ul">{`Improved Typescript and documentation`}</li>
        </ul>
      </li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-223"
    }}>{`[v. 2.2.3]`}</h4>
    <ul>
      <li parentName="ul">{`Init Carousel Component`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel / Mo Mustafa`}</em></p>
    <h4 {...{
      "id": "v-222"
    }}>{`[v. 2.2.2]`}</h4>
    <ul>
      <li parentName="ul">{`Updated Typography Component`}</li>
    </ul>
    <p><em parentName="p">{`Lee Collins`}</em></p>
    <h4 {...{
      "id": "v-221"
    }}>{`[v. 2.2.1]`}</h4>
    <ul>
      <li parentName="ul">{`Init Tabs Component`}</li>
    </ul>
    <p><em parentName="p">{`Zoe Jablow`}</em></p>
    <h3 {...{
      "id": "v-220"
    }}>{`[v. 2.2.0]`}</h3>
    <ul>
      <li parentName="ul">{`Init Typography Component`}</li>
    </ul>
    <p><em parentName="p">{`Lee Collins`}</em></p>
    <h3 {...{
      "id": "v-210"
    }}>{`[v. 2.1.0]`}</h3>
    <ul>
      <li parentName="ul">{`Init Pagination Component`}</li>
    </ul>
    <p><em parentName="p">{`Lee Collins`}</em></p>
    <h4 {...{
      "id": "v-204"
    }}>{`[v. 2.0.4]`}</h4>
    <ul>
      <li parentName="ul">{`Fix input autofill style`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h4 {...{
      "id": "v-203"
    }}>{`[v. 2.0.3]`}</h4>
    <ul>
      <li parentName="ul">{`Remove duplication of Accordion styling`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-201-2"
    }}>{`[v. 2.0.1-2]`}</h4>
    <ul>
      <li parentName="ul">{`fixes to treeshaking`}</li>
      <li parentName="ul">{`Moved everything in /src up a directory`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h2 {...{
      "id": "v-200"
    }}>{`[v. 2.0.0]`}</h2>
    <ul>
      <li parentName="ul">{`Treeshaking capability (removes unused components)`}</li>
      <li parentName="ul">{`All imports are now in this format `}<inlineCode parentName="li">{`import Button from 'mfcl/Button'`}</inlineCode></li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-1152-7"
    }}>{`[v. 1.15.2-7]`}</h4>
    <ul>
      <li parentName="ul">{`Treeshaking testing, but not quite there :)`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-1151"
    }}>{`[v. 1.15.1]`}</h4>
    <ul>
      <li parentName="ul">{`Accordion styles and added collapse to the Accordion`}</li>
      <li parentName="ul">{`Docz correct styling`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h3 {...{
      "id": "v-1150"
    }}>{`[v. 1.15.0]`}</h3>
    <ul>
      <li parentName="ul">{`Rewrite Input internals`}
        <ul parentName="li">
          <li parentName="ul">{`Changed inputMessage to just a string`}</li>
          <li parentName="ul">{`Added forwardRef to input`}</li>
          <li parentName="ul">{`added onChange, className, rightSide props`}</li>
          <li parentName="ul">{`deleted inputClass, addBtn, btnLabel, btnProps, focus props`}</li>
        </ul>
      </li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h4 {...{
      "id": "v-1141"
    }}>{`[v. 1.14.1]`}</h4>
    <ul>
      <li parentName="ul">{`Adjust Input style`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h3 {...{
      "id": "v-1140"
    }}>{`[v. 1.14.0]`}</h3>
    <ul>
      <li parentName="ul">{`Adjust Input style`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h4 {...{
      "id": "v-1131"
    }}>{`[v. 1.13.1]`}</h4>
    <ul>
      <li parentName="ul">{`Update Input with new styles`}</li>
    </ul>
    <p><em parentName="p">{`Lee Collins`}</em></p>
    <ul>
      <li parentName="ul">{`Add Grid Component Prop`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h3 {...{
      "id": "v-1130"
    }}>{`[v. 1.13.0]`}</h3>
    <ul>
      <li parentName="ul">{`Init Panel Component`}</li>
      <li parentName="ul">{`Init PanelItem Component`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa`}</em></p>
    <h3 {...{
      "id": "v-1120"
    }}>{`[v. 1.12.0]`}</h3>
    <ul>
      <li parentName="ul">{`Init Grid Component`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h3 {...{
      "id": "v-1110"
    }}>{`[v. 1.11.0]`}</h3>
    <ul>
      <li parentName="ul">{`Init Number Incrementer`}</li>
    </ul>
    <p><em parentName="p">{`Lee Collins`}</em></p>
    <h3 {...{
      "id": "v-1100"
    }}>{`[v. 1.10.0]`}</h3>
    <ul>
      <li parentName="ul">{`Init Stepper`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h3 {...{
      "id": "v-190"
    }}>{`[v. 1.9.0]`}</h3>
    <ul>
      <li parentName="ul">{`Init Textarea Component`}</li>
    </ul>
    <p><em parentName="p">{`Mo Mustafa / Carlos Rangel`}</em></p>
    <h3 {...{
      "id": "v-180"
    }}>{`[v. 1.8.0]`}</h3>
    <ul>
      <li parentName="ul">{`Init Input Component`}</li>
    </ul>
    <p><em parentName="p">{`Carlos Rangel / Mo Mustafa`}</em></p>
    <h3 {...{
      "id": "v-170"
    }}>{`[v. 1.7.0]`}</h3>
    <ul>
      <li parentName="ul">{`Init Badge Component`}</li>
    </ul>
    <p><em parentName="p">{`Lee Collins`}</em></p>
    <h3 {...{
      "id": "v-160"
    }}>{`[v. 1.6.0]`}</h3>
    <ul>
      <li parentName="ul">{`Modal Component`}</li>
      <li parentName="ul">{`Portal Component`}</li>
    </ul>
    <p><em parentName="p">{`Zoe Jablow`}</em></p>
    <h3 {...{
      "id": "v-150"
    }}>{`[v. 1.5.0]`}</h3>
    <ul>
      <li parentName="ul">{`Init Flag Component`}</li>
    </ul>
    <h3 {...{
      "id": "v-141"
    }}>{`[v. 1.4.1]`}</h3>
    <ul>
      <li parentName="ul">{`Init Tooltip Component`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h3 {...{
      "id": "v-140"
    }}>{`[v. 1.4.0]`}</h3>
    <ul>
      <li parentName="ul">{`Init Price Component`}</li>
    </ul>
    <p><em parentName="p">{`Lee Collins`}</em></p>
    <h3 {...{
      "id": "v-130"
    }}>{`[v. 1.3.0]`}</h3>
    <ul>
      <li parentName="ul">{`Init ProductCard Component`}</li>
    </ul>
    <p><em parentName="p">{`Lee Collins`}</em></p>
    <h3 {...{
      "id": "v-120"
    }}>{`[v. 1.2.0]`}</h3>
    <ul>
      <li parentName="ul">{`Updated Button Component Designs`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h3 {...{
      "id": "v-110"
    }}>{`[v. 1.1.0]`}</h3>
    <ul>
      <li parentName="ul">{`Init Accordion Component`}</li>
    </ul>
    <p><em parentName="p">{`Christy Koesters`}</em></p>
    <h2 {...{
      "id": "v-100"
    }}>{`[v. 1.0.0]`}</h2>
    <ul>
      <li parentName="ul">{`SSR for all modules`}</li>
      <li parentName="ul">{`Init Rating Component`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>
    <h3 {...{
      "id": "v-010"
    }}>{`[v. 0.1.0]`}</h3>
    <p>{`Created the build, the website, documentation and the following components:`}</p>
    <ul>
      <li parentName="ul">{`Alert`}</li>
      <li parentName="ul">{`Button`}</li>
      <li parentName="ul">{`Checkbox`}</li>
      <li parentName="ul">{`Collapse`}</li>
      <li parentName="ul">{`Drawer`}</li>
      <li parentName="ul">{`Dropdown`}</li>
      <li parentName="ul">{`Fade`}</li>
      <li parentName="ul">{`Loading`}</li>
      <li parentName="ul">{`ProgressBar`}</li>
      <li parentName="ul">{`Select`}</li>
    </ul>
    <p><em parentName="p">{`Craig Walker`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      